//
// Container Elements
//
:root {
  --height-header: 2.5rem;
  --height-nav: 2rem;

  @include media('xxs') {
    --height-header: 3.5rem;
    --height-nav: 3rem;
  }

  @include media('sm') {
    --height-header: 3.8rem;
    --height-nav: 3.3rem;
  }

  @include media('lg') {
    --height-header: 3.6rem;
    --height-nav: 3.6rem;
  }

  @include media('xl') {
    --height-header: 4.33rem;
    --height-nav: 4.33rem;
  }
}
