.DevTools {
  z-index: 500;
  position: fixed;
  bottom: 1.5rem;
  background-color: red;

  input {
    background-color: white;
    color: black;
  }

  section {
    margin: 0.5rem 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
