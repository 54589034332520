/*
   DISPLAY
   Docs: http://tachyons.io/docs/layout/display
   Base:
    d = display
   Modifiers:
    n     = none
    b     = block
    i     = inline
    ib    = inline-block
   Media Query Extensions:
     -md  = medium (768 up)
     -lg  = large (1024 up)
     -xl  = extra large (1440 up)
     -xxl = extra extra large (1920 up)
*/

.dn {
  display: none;
}

.di {
  display: inline;
}

.db {
  display: block;
}

.dib {
  display: inline-block;
}

@media (query(md-up)) {
  .dn-md {
    display: none;
  }
  .di-md {
    display: inline;
  }
  .db-md {
    display: block;
  }
  .dib-md {
    display: inline-block;
  }
}

@media (query(lg-up)) {
  .dn-lg {
    display: none;
  }
  .di-lg {
    display: inline;
  }
  .db-lg {
    display: block;
  }
  .dib-lg {
    display: inline-block;
  }
}

@media (query(xl-up)) {
  .dn-xl {
    display: none;
  }
  .di-xl {
    display: inline;
  }
  .db-xl {
    display: block;
  }
  .dib-xl {
    display: inline-block;
  }
}

@media (query(xxl-up)) {
  .dn-xxl {
    display: none;
  }
  .di-xxl {
    display: inline;
  }
  .db-xxl {
    display: block;
  }
  .dib-xxl {
    display: inline-block;
  }
}
