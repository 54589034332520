.Nav {
  height: var(--height-nav);
  position: sticky;
  top: calc(var(--height-header));
  width: 100%;
  z-index: map-get($zIndex, 'medium');
  border-top: 1px solid color('darkestgray');
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
  user-select: none;

  ul {
    height: 100%;
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    border-bottom: 1px solid color('darkestgray');
    background-color: color('black');
  }

  &__item {
    height: 100%;
    width: 100%;
    border-right: 1px solid color('darkestgray');
    border-bottom: 1px solid color('darkestgray');
    background-color: color('black');
    user-select: text;
    position: relative;

    &:last-child {
      border-right: none;
    }

    a {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 0.2em;

      & span {
        position: relative;
      }
    }

    &-active {
      border-bottom: none;
      padding-bottom: 1px;

      & a {
        cursor: default;
      }
    }
  }

  &__tutorialtrigger {
    display: none;
    justify-content: center;
    align-items: center;
    padding: 0.7em;
    transform: translate(100%, -0.7em);
    position: absolute;
    right: 0;
    top: 0;
    animation: fade-in 0.3s ease-in-out;

    &-active {
      @include media('xxs') {
        display: grid;
      }
    }

    @include media('sm') {
      padding: 0.5em;
      transform: translate(100%, -0.5em);
    }
  }

  &__playlist-generator {
    @include media('sm-down') {
      &::before {
        content: '+ ';
      }
    }
    @include media('md') {
      &::after {
        content: ' Generator';
      }
    }
  }

  &__loader {
    color: color('darkgray');
    transition: color 0.3s ease-in-out;

    &:hover {
      color: color('gray');
    }
  }

  &__loaderstate {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.8em;
  }

  &__loaderprogress {
    width: 1.5em;
    height: 1.5em;
    transform-origin: 50% 50%;
    transform: rotate(-90deg);

    &-wheel {
      transition: stroke-dashoffset 0.4s ease-in-out;
      stroke-dasharray: 113.04 113.04;
      transform-origin: 50% 50%;

      &-infinite {
        animation: rotate-360 1s ease infinite;
      }
    }
  }
}
