/*

   FLOATS
   http://tachyons.io/docs/layout/floats/

   1. Floated elements are automatically rendered as block level elements.
      Setting floats to display inline will fix the double margin bug in
      ie6. You know... just in case.

   2. Don't forget to clearfix your floats with .cf

   Base:
     f = float

   Modifiers:
     l = left
     r = right
     n = none

   Media Query Extensions:
     -md  = medium (768 up)
     -lg  = large (1024 up)
     -xl  = extra large (1440 up)
     -xxl = extra extra large (1920 up)

*/

.fl {
  float: left;
  _display: inline;
}

.fr {
  float: right;
  _display: inline;
}

.fn {
  float: none;
}

@media (query(md-up)) {
  .fl-md {
    float: left;
    _display: inline;
  }
  .fr-md {
    float: right;
    _display: inline;
  }
  .fn-md {
    float: none;
  }
}

@media (query(lg-up)) {
  .fl-lg {
    float: left;
    _display: inline;
  }
  .fr-lg {
    float: right;
    _display: inline;
  }
  .fn-lg {
    float: none;
  }
}

@media (query(xl-up)) {
  .fl-xl {
    float: left;
    _display: inline;
  }
  .fr-xl {
    float: right;
    _display: inline;
  }
  .fn-xl {
    float: none;
  }
}

@media (query(xxl-up)) {
  .fl-xxl {
    float: left;
    _display: inline;
  }
  .fr-xxl {
    float: right;
    _display: inline;
  }
  .fn-xxl {
    float: none;
  }
}
