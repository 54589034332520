/*
 * Popover.scss
 * author: evan kirkiles
 * created on Sat Apr 01 2023
 * 2023 channel studio
 */

.Popover {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0.6em auto;
  background-color: color('black');
  border: 1px solid color('darkgray');
  border-radius: 10px;
  z-index: zIndex('medium');
  padding: 1em;
  pointer-events: all;
  max-width: min(30em, 100%);
  cursor: auto;
  user-select: text;
  animation: fade-in 0.4s ease-in-out;
  transition: bottom 0.3s ease-in-out, left 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  width: calc(100% - 1.2em);

  @include media('md-down') {
    left: 0.6em;
  }

  @include media('lg') {
    margin: 1.5em;
  }

  &__content {
    line-height: 1.2em;
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }

  &__dismiss {
    width: 1.2em;
    height: 1.2em;
    font-size: 1.5em;
    display: grid;
    justify-content: center;
    align-items: center;
    transform: translate(0em, -0.15em);
    flex-shrink: 0;
  }

  &-shiftup {
    @include media('md-down') {
      bottom: 5.5em;
    }
  }

  &-showslider {
    @include media('md-down') {
      margin: 0.6em;
      left: unset;
      bottom: unset;
      right: 0;
      top: calc(var(--height-header) + var(--height-nav));
    }
  }

  &-loading {
    bottom: unset;
    left: unset;
    top: calc(var(--height-header) + 1.75em);
    right: 2.5em;
    max-width: min(calc(100vw - 5em), min(30em, calc(100% - 2em)));
  }

  .Container-inactive & {
    pointer-events: none;
    visibility: hidden;
  }

  &__navigation {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;

    &_badge {
      color: color('gray');
    }

    &_buttons {
      display: flex;
      flex-direction: row;
      gap: 1em;
    }
  }

  &__button {
    padding: 0;
    border: 1px solid color('darkestgray');
    border-radius: 15px;
    padding: 0.2em 1.5em;
    min-width: 6em;

    &_prev {
      @include media('xxxs-down') {
        display: none;
      }
    }

    &:not(&-disabled):hover {
      border-color: color('darkgray');
    }

    // &-disabled {
    //   visibility: hidden;
    //   user-select: none;
    //   pointer-events: none;
    // }
  }

  &__mask {
    animation: fade-in-07 0.5s ease-in-out;

    & rect {
      pointer-events: inherit !important;
    }
  }

  &__outer {
    animation: fade-in 0.4s ease-in-out;
    animation-delay: 0.4s;
    animation-fill-mode: backwards;
  }

  &__hand {
    pointer-events: none;
    height: 3em;
    width: auto;
    animation: bob 2s ease-in-out infinite;
    z-index: zIndex('high');
  }

  &-first:not(.Popover-showslider) {
    bottom: 50%;
    top: unset;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  &-first {
    @include media('lg-up') {
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, 50%);
    }
  }
}
