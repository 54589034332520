//
// Container Elements
//
.Container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: auto;

  &:not(.Umap__container) {
    cursor: auto;
  }

  @include media('md') {
    height: calc(100vh - var(--height-header) - var(--height-nav));
  }

  &-fixed {
    position: fixed;
    height: calc(100% - var(--height-header) - var(--height-nav));
    top: calc(var(--height-header) + var(--height-nav));
    left: 0;
    overflow: hidden;
  }

  &__inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  &-inactive {
    opacity: 0;
    pointer-events: none;
  }

  &-404 {
    gap: 0.5em;
  }
}

.Interface {
  &__popoveranchor {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 1px;

    &-shiftup {
      @include media('md-down') {
        bottom: 4em;
      }
    }
  }
}
