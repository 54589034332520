/*
   HEIGHTS
   Base:
    h = height
   Modifiers:
    100   = 100 vh
    75    = 75 vh
    50    = 50 vh
    a     = auto
   Media Query Extensions:
     -md  = medium (768 up)
     -lg  = large (1024 up)
     -xl  = extra large (1440 up)
     -xxl = extra extra large (1920 up)
*/

.mh-100 {
  min-height: 100vh;
}

.h-100 {
  height: 100vh;
}

.h-75 {
  height: 75vh;
}

.h-50 {
  height: 50vh;
}

.h-a {
  height: auto;
}

@media (query(md-up)) {
  .mh-100-md {
    min-height: 100vh;
  }
  .h-100-md {
    height: 100vh;
  }
  .h-75-md {
    height: 75vh;
  }
  .h-50-md {
    height: 50vh;
  }
  .h-a-md {
    height: auto;
  }
}

@media (query(lg-up)) {
  .mh-100-lg {
    min-height: 100vh;
  }
  .h-100-lg {
    height: 100vh;
  }
  .h-75-lg {
    height: 75vh;
  }
  .h-50-lg {
    height: 50vh;
  }
  .h-a-lg {
    height: auto;
  }
}

@media (query(xl-up)) {
  .mh-100-xl {
    min-height: 100vh;
  }
  .h-100-xl {
    height: 100vh;
  }
  .h-75-xl {
    height: 75vh;
  }
  .h-50-xl {
    height: 50vh;
  }
  .h-a-xl {
    height: auto;
  }
}

@media (query(xxl-up)) {
  .mh-100-xxl {
    min-height: 100vh;
  }
  .h-100-xxl {
    height: 100vh;
  }
  .h-75-xxl {
    height: 75vh;
  }
  .h-50-xxl {
    height: 50vh;
  }
  .h-a-xxl {
    height: auto;
  }
}
