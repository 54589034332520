.Header {
  position: sticky;
  padding: 0.5em;
  top: 0px;
  z-index: map-get($zIndex, 'high');
  width: 100%;
  height: var(--height-header);
  background-color: color('black');
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  // on large displays, we need to show play preview underneath the header.
  // the z-index stacking is super complex with the expandable menu needing to
  // show over the preview player.
  @include media('lg') {
    padding-right: 1.5em;
    background-color: transparent;
  }

  &__inner {
    height: 100%;
    gap: 0.5em;
    justify-content: flex-end;
    flex: 1;
    padding-right: 1em;
  }

  &__logo {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    z-index: 2;
    pointer-events: all;
    position: relative;

    @include media('lg') {
      padding: 0em 1em;
    }

    &-icon {
      height: 1.65em;
      padding-left: 0.1em;
      transform: translateZ(100px);
      color: color("white");

      @include media('lg') {
        font-size: 1.4;
      }
    }

    &-icon2 {
      height: 1.65em;
      padding-left: 0.1em;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__cs {
    pointer-events: auto;
    white-space: nowrap;
    margin-right: auto;
    z-index: 2;

    @include media('md-down') {
      margin-left: 1em;
    }

    @include media('sm-down') {
      display: none;
    }
  }

  &__account-details {
    color: color('blue');
    font-size: 0.75rem;
    line-height: 1;
    border-right: 1px solid color('darkestgray');
    white-space: nowrap;
    padding: 0 1em;
    gap: 0.8rem;

    @include media('xs-down') {
      display: none;
    }

    button {
      display: block;
      color: inherit;
      font-size: inherit;
      line-height: inherit;
      text-decoration: underline;
      text-underline-offset: 0.125em;
      white-space: inherit;
    }
  }

  &__links {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 1.5em;
    animation: fade-slide-left 0.3s ease-in-out;

    @include media('lg') {
      padding: 1.5em;
      animation: fade-slide-right 0.3s ease-in-out;
    }
  }

  &__link {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    pointer-events: all;
  }

  &__logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    pointer-events: all;
    gap: 0em;

    @include media("lg") {
      gap: 0.75em;
    }
  }

  &__dev {
    position: absolute;
    top: 0;
    pointer-events: all;
  }

  &__hamburger {
    color: color('darkgray');
    position: relative;
    padding: 0.75em 1.25em;
    width: calc(0.8 * var(--height-header));
    height: calc(0.8 * var(--height-header));
    z-index: 2;
    pointer-events: all;

    &:after,
    &:before {
      content: '';
      position: absolute;
      transform-origin: 50% 50%;
      top: 50%;
      left: 10%;
      height: 1px;
      width: 80%;
      background-color: color('darkgray');
      transition: transform ease-in-out 0.075s 0.05s,
        background-color 0.1s ease-in-out;
      will-change: transform;
    }

    &:before {
      transform: translateY(-0.3em) rotate(0deg);
    }

    &:after {
      transform: translateY(0.3em) rotate(0deg);
    }

    &-expanded {
      &:after {
        transform: translateY(0) rotate(-45deg);
        background-color: color('white');
      }

      &:before {
        transform: translateY(0) rotate(45deg);
        background-color: color('white');
      }
    }
  }
}
