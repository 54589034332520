/*
 * Menu.scss
 * author: evan kirkiles
 * created on Wed Feb 01 2023
 * 2023 channel studio
 */

.Menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: color('black');
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
  padding-left: 0.5em;
  padding-top: var(--height-header);
  max-width: 100vw;
  overflow: hidden;

  @include media('lg') {
    padding-left: 1.5em;
  }

  &__wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }

  &-expanded {
    opacity: 1;
    pointer-events: all;
  }

  &:not(&-expanded) &__subview {
    pointer-events: none !important;
  }

  &__items {
    font-size: 3.75em;
    line-height: 0.9em;
    position: relative;

    @include media('md') {
      font-size: 6.875em;
    }

    // active list elements are colored on hover
    & > li:not(.Menu__legal) {
      transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

      & a,
      & button {
        padding: 0 0.2em;
        margin-top: -0.1em;
        margin-left: -0.2em;
        transition: color 0.1s ease-in-out;
        line-height: 1.1em;
        display: block;
        width: min-content;
      }

      &:nth-of-type(1) a,
      &:nth-of-type(1) button {
        &:hover,
        &.current {
          color: color('gray');
        }
      }

      &:nth-of-type(2) a,
      &:nth-of-type(2) button {
        &:hover,
        &.current {
          color: color('green');
        }
      }

      &:nth-of-type(3) a,
      &:nth-of-type(3) button {
        &:hover,
        &.current {
          color: color('red');
        }
      }

      &:nth-of-type(4) a,
      &:nth-of-type(4) button {
        &:hover,
        &.current {
          color: color('purple');
        }
      }
    }

    // inactive list elements faded left
    &-inactive > li {
      opacity: 0;
      transform: translateX(-10px);
    }

    // but the one whose view is expanding does not
    &-profile-view > .Menu__li_profile,
    &-about-view > .Menu__li_about {
      transform: none;
    }
  }

  /* --------------------------------- subview -------------------------------- */

  &__subview {
    position: absolute;
    line-height: 0.9em;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - var(--height-header));
    overflow: scroll;
    pointer-events: none;
    opacity: 0;
    // padding: inherit;
    padding-bottom: 1em;

    &_backbutton {
      font-size: 0.5em;
      line-height: 1em;
    }

    &-active {
      opacity: 1;
      pointer-events: all;
    }
  }

  /* --------------------------------- profile -------------------------------- */

  &__profile {
    & li {
      max-width: 100vw;
      width: 100%;
    }

    &_info {
      cursor: auto;
      text-transform: capitalize;

      & span {
        // display: inline-block;
        display: inline;
        text-transform: none;
        color: color('darkgray');
        word-wrap: break-word;
      }
    }

    &_action button {
      transition: color 0.1s ease-in-out;
      line-height: 1.1em;
      text-align: left;
      display: block;

      &:hover {
        color: color('lightred');
      }
    }

    &.Menu__subview-active {
      & > .Menu__profile_action,
      & > .Menu__profile_info {
        animation: fade-slide-right 0.2s ease-in-out;
        animation-delay: 0.6s;
        animation-fill-mode: both;
      }
    }

    &_header button {
      transition: transform 0.4s ease-out !important;
      transition-delay: 0.2s !important;
      transform: translateY(1.45em);
      color: color('red');
      text-align: left;
      display: block;

      &:hover {
        opacity: 0.7;
      }

      .Menu__subview-active & {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &__about {
    &.Menu__subview-active {
      & > .Menu__about_action,
      & > .Menu__about_body {
        animation: fade-slide-right 0.2s ease-in-out;
        animation-delay: 0.6s;
        animation-fill-mode: both;
      }
    }

    &_header button {
      transition: transform 0.4s ease-out !important;
      transition-delay: 0.2s !important;
      transform: translateY(0.475em);
      color: color('green');
      text-align: left;
      display: block;

      &:hover {
        opacity: 0.7;
      }

      span {
        opacity: 0;
      }

      .Menu__subview-active & {
        opacity: 1;
        transform: translateY(0);

        span {
          opacity: 1;
          transition: opacity 0.4s ease-in-out;
          transition-delay: 0.6s;
        }
      }
    }

    &_body {
      & p > a {
        display: inline !important;
        margin: 0 !important;
        padding: 0 !important;
      }
      h1 {
        margin-top: 2rem;
        font-size: 1.5rem;
        line-height: 1.23em;
        @media (query(md-up)) {
          margin-top: 4rem;
          font-size: 6.25rem;
        }
      }

      em {
        font-style: italic;
      }

      p,
      li,
      h2 {
        font-size: 1rem;
        line-height: 1.23em;
        @media (query(md-up)) {
          margin-left: 0.25rem;
          font-size: 1.5rem;
        }
      }

      p,
      h2 {
        margin-top: 2.25rem;
      }

      p,
      ul {
        @media (query(md-up)) {
          width: 50vw;
        }
      }

      p + &_smaller_p {
        margin-top: 1.5rem;
        font-size: 0.75rem;
        @media (query(md-up)) {
          font-size: 1.125rem;
        }
      }

      &_bulleted {
        margin-top: 2.25rem;

        li {
          display: list-item;
          margin-left: 2.25rem;
          font-size: 0.75rem;
          @media (query(md-up)) {
            font-size: 1.125rem;
          }
        }
      }

      a {
        text-decoration: underline;
      }
    }
  }

  &__legal {
    padding-left: 0.1em;
    font-size: 0.3em;
    margin-top: 0.4em;
    line-height: 1em;

    @include media('md') {
      padding-left: 0.3em;
    }

    & > a {
      transition: color 0.1s ease-in-out;

      &:hover {
        color: color('blue');
      }
    }
  }

  &__studiolink {
    transition: color 0.1s ease-in-out;
    &:hover {
      color: color('purple');
    }
  }

  &__credit {
    position: absolute;
    z-index: 2;
    padding: 1em;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: color('black');
    cursor: auto;
  }
}
