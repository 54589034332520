.Bookmarks {
  display: flex;
  flex-direction: column;
  width: 100%;

  .Searchfield {
    width: 100%;
    max-width: 1000px;
  }

  &__thead {
    top: calc(var(--height-header) + var(--height-nav) + 5em);
  }

  &__header {
    position: sticky;
    top: calc(var(--height-header) + var(--height-nav));
    background-color: color('black');
    width: 100%;
    z-index: map-get($zIndex, 'medium');
    height: 5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 1.5em;

    @include media('sm-down') {
      border-bottom: 1px solid color('darkestgray');
    }
  }

  &__empty {
    justify-content: center;

    p {
      align-self: center;
    }

    @include media('sm-down') {
      height: calc(100vh - var(--height-header) - var(--height-nav));
    }
  }
}
