/*
    BORDERS
    Docs: http://tachyons.io/docs/themes/borders/
    Base:
      b = border
    Modifiers:
      a = all
      t = top
      r = right
      b = bottom
      l = left
      n = none
    Media Query Extensions:
      -md  = medium (768 up)
      -lg  = large (1024 up)
      -xl  = extra large (1440 up)
      -xxl = extra extra large (1920 up)
*/

.ba {
  border-style: solid;
  border-width: 1px;
}

.bt {
  border-top-style: solid;
  border-top-width: 1px;
}

.br {
  border-right-style: solid;
  border-right-width: 1px;
}

.bb {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.bl {
  border-left-style: solid;
  border-left-width: 1px;
}

.bn {
  border-style: none;
  border-width: 0;
}

@media (query(md-up)) {
  .ba-md {
    border-style: solid;
    border-width: 1px;
  }
  .bt-md {
    border-top-style: solid;
    border-top-width: 1px;
  }
  .br-md {
    border-right-style: solid;
    border-right-width: 1px;
  }
  .bb-md {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  .bl-md {
    border-left-style: solid;
    border-left-width: 1px;
  }
  .bn-md {
    border-style: none;
    border-width: 0;
  }
}

@media (query(lg-up)) {
  .ba-lg {
    border-style: solid;
    border-width: 1px;
  }
  .bt-lg {
    border-top-style: solid;
    border-top-width: 1px;
  }
  .br-lg {
    border-right-style: solid;
    border-right-width: 1px;
  }
  .bb-lg {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  .bl-lg {
    border-left-style: solid;
    border-left-width: 1px;
  }
  .bn-lg {
    border-style: none;
    border-width: 0;
  }
}

@media (query(xl-up)) {
  .ba-xl {
    border-style: solid;
    border-width: 1px;
  }
  .bt-xl {
    border-top-style: solid;
    border-top-width: 1px;
  }
  .br-xl {
    border-right-style: solid;
    border-right-width: 1px;
  }
  .bb-xl {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  .bl-xl {
    border-left-style: solid;
    border-left-width: 1px;
  }
  .bn-xl {
    border-style: none;
    border-width: 0;
  }
}

@media (query(xxl-up)) {
  .ba-xxl {
    border-style: solid;
    border-width: 1px;
  }
  .bt-xxl {
    border-top-style: solid;
    border-top-width: 1px;
  }
  .br-xxl {
    border-right-style: solid;
    border-right-width: 1px;
  }
  .bb-xxl {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  .bl-xxl {
    border-left-style: solid;
    border-left-width: 1px;
  }
  .bn-xxl {
    border-style: none;
    border-width: 0;
  }
}
