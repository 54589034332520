/*
  TEXT ALIGN
  Docs: http://tachyons.io/docs/typography/text-align/
  Base
    t = text-align
  Modifiers
    l = left
    r = right
    c = center
    j = justify
  Media Query Extensions:
    -md  = medium (768 up)
    -lg  = large (1024 up)
    -xl  = extra large (1440 up)
    -xxl = extra extra large (1920 up)
*/

.tl {
  text-align: left;
}

.tr {
  text-align: right;
}

.tc {
  text-align: center;
}

.tj {
  text-align: justify;
}

@media (query(md-up)) {
  .tl-md {
    text-align: left;
  }
  .tr-md {
    text-align: right;
  }
  .tc-md {
    text-align: center;
  }
  .tj-md {
    text-align: justify;
  }
}

@media (query(lg-up)) {
  .tl-lg {
    text-align: left;
  }
  .tr-lg {
    text-align: right;
  }
  .tc-lg {
    text-align: center;
  }
  .tj-lg {
    text-align: justify;
  }
}

@media (query(xl-up)) {
  .tl-xl {
    text-align: left;
  }
  .tr-xl {
    text-align: right;
  }
  .tc-xl {
    text-align: center;
  }
  .tj-xl {
    text-align: justify;
  }
}

@media (query(xxl-up)) {
  .tl-xxl {
    text-align: left;
  }
  .tr-xxl {
    text-align: right;
  }
  .tc-xxl {
    text-align: center;
  }
  .tj-xxl {
    text-align: justify;
  }
}
