/*

   CLEARS

*/

/* Nicolas Gallaghers Clearfix solution
   Ref: http://nicolasgallagher.com/micro-clearfix-hack/ */

.cf:before,
.cf:after {
  content: ' ';
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  *zoom: 1;
}
