.Cluster {
  display: flex;
  flex-direction: column;

  &-inactive {
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }

  &__navwrapper {
    overflow: scroll hidden;
    width: 100%;
    scrollbar-width: none;
    position: sticky;
    z-index: map-get($zIndex, 'medium');
    height: 7em;
    background-color: color('black');
    top: calc(var(--height-header) + var(--height-nav));

    @include media('sm-down') {
      border-bottom: 1px solid color('darkestgray');
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 1);
    }
  }

  &__nav {
    padding: 3.125em 0;
    scrollbar-width: none;

    & li {
      padding-right: 6em;

      // white-space: nowrap;
    }

    & li:first-child {
      margin-left: 9.875em;
    }

    & button {
      white-space: nowrap;
      line-height: 1.2em;
      padding: 0.5rem;
      margin: -0.5rem;
    }
  }

  &__thead {
    top: calc(var(--height-header) + var(--height-nav) + 7em);
  }
}
