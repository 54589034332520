.AboutPage {
  padding: 1em 1rem;
  height: unset;
  opacity: 1;
  overflow: unset;
  pointer-events: unset;

  &__header {
    position: unset !important;
  }

  &__title {
    margin-bottom: 1em;
  }
}
