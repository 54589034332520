.Playlist {
  height: unset;

  @include media('lg') {
    position: absolute;
    height: calc(100vh - var(--height-header) - var(--height-nav));
    top: calc(var(--height-header) + var(--height-nav));
    left: 0;
    overflow: hidden;
  }

  &__clear {
    position: sticky;
    top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
    padding: 1.5em;
    line-height: 1.1em;
    background-color: color('black');
    border-bottom: 1px solid color('darkestgray');
    z-index: map-get($zIndex, 'medium') - 2;
    white-space: nowrap;
    user-select: text;

    @include media('md-down') {
      top: calc(var(--height-header) + var(--height-nav));
    }

    em {
      color: color('darkgray');
    }

    span {
      gap: 0.75em;
    }
  }

  &__clear,
  &__export {
    & button {
      height: 2em;
      padding: 0 1em;
      transition: transform 0.1s ease, color 0.3s ease, border-color 0.3s ease;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:hover {
        transform: scale(0.9);
      }

      & svg {
        height: 1em;
        width: 1em;
      }
    }
  }

  &__export {
    p {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5em;
    }

    span {
      display: none;
      @include media('xs') {
        display: block;
      }
    }
  }

  &__button-exporting {
    border-color: color('darkgray');
    position: relative;
    pointer-events: none;

    & img {
      animation: rotation 2s infinite linear;
      height: 1em;
    }

    & div {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      display: flex;
    }

    & p {
      visibility: hidden;
    }
  }

  &__songsadjacent {
    color: color('darkgray');
    margin: 0.3em 0em;
  }

  &__button-deactive {
    border-color: color('darkgray');
    color: color('darkgray');
    pointer-events: none;
  }

  &__generator {
    scrollbar-width: none;
    flex: 1;
    user-select: none;

    @include media('md-down') {
      z-index: 0;
    }

    @include media('lg') {
      height: 100%;
      overflow-y: scroll;
      border-right: 1px solid color('darkestgray');
    }
  }

  &__targets {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 2em 0;
    margin-bottom: 5em;
    gap: 0.75em;
    user-select: text;

    & > small {
      margin-top: 1em;
      width: 85%;
      font-style: italic;
      text-align: left;
      color: color('darkgray');
    }
  }

  &__paths {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: -0.5em 0;
  }

  &__loading {
    margin: 1.875rem 0;

    &circle {
      width: 4em;
      height: 4em;
    }
  }

  &__target {
    padding: 0 2em;
    height: 5.25em;
    border-radius: 0.5em;
    width: 90%;
    color: color('darkgray');
    border: 1px dashed color('darkestgray');
    display: flex;
    transition: background-color 0.1s ease, width 0.1s ease-in-out;
    align-items: center;

    &-hover {
      background-color: color('white');
      color: color('black');
    }

    &-selected {
      background-color: color('darkestgray');
      width: 80%;
    }

    &:not(&-cantclick) {
      cursor: pointer;
    }

    &:not(&-cantclick):hover {
      background-color: color('darkestgray');
    }

    &-replace {
      margin-left: 2em;
    }
  }

  &__track {
    display: flex;
    align-items: center;
    padding: 0.3em;
    border-radius: 0.5em;
    border-style: solid;
    border-color: color('darkgray');
    gap: 1em;
    min-width: 0;
    cursor: auto;

    & > img {
      width: 3em;
      height: 3em;
      margin-left: -1em;
      object-fit: contain;
      object-position: center;
    }

    & h1 {
      color: color('white');
      max-width: 100%;
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & p {
      color: color('darkgray');
      max-width: 100%;
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & div {
      flex: 1 1;
      min-width: 0;
      overflow: hidden;
      width: 0;
    }

    .highlighted {
      color: color('black');
      background-color: color('white');
    }

    &-draggable {
      width: fit-content;
      margin: 0 !important;
      cursor: grab;
    }

    &-dragging {
      opacity: 0.5;
    }
  }

  &__button {
    padding: 1em;

    & svg {
      width: 1.5em;
      height: 1.5em;
    }

    transition: transform 0.1s ease-in-out;

    &:hover {
      transform: scale(1.3);
    }
  }

  &__generated {
    border-bottom: 1px solid color('darkestgray');
    border-radius: 0;
    width: calc(90% - 2em);
    z-index: 0;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include media('sm-down') {
      border-bottom: 1px solid color('darkestgray');
    }
  }

  &__navbutton {
    color: color('darkgray');

    &-selected {
      text-decoration: underline;
      text-underline-offset: 0.3em;
      color: color('white');
    }
  }

  &__search {
    flex: 1;

    @include media('md-down') {
      margin-right: 1em;
    }

    @include media('sm-down') {
      margin-right: 0;
    }
  }

  &__tracks {
    height: 100%;
    flex: 1;
    overflow: hidden;
    background-color: color('black');

    // this implements the modal version of tracks
    @include media('md-down') {
      position: fixed;
      top: 0;
      left: 0;
      overflow: hidden;
      border-top: 1px solid color('darkestgray');
      transform: translateY(calc(var(--height-header) + 1em));
      transition: transform 0.4s ease;
      width: 100%;
      z-index: map-get($zIndex, 'medium');

      &-closed {
        transform: translateY(100%);
        transition: transform 0.4s ease;
      }
    }

    & nav,
    ul {
      width: 90%;
      scrollbar-width: none;
    }

    & nav {
      width: 100%;
      padding: 1.1rem 2em;
      padding-bottom: 0px;
      gap: 2em;
      position: relative;

      & section {
        gap: 2em;
      }

      @include media('md-down') {
        padding-bottom: 1.1rem;
      }

      @include media('sm-down') {
        flex-direction: column;
        gap: 1.5em;
      }
    }

    & ul {
      height: 100%;
      overflow-y: scroll;
      margin: 3.125rem auto;
    }

    & li {
      margin: 0 auto;
    }
  }

  &__table {
    width: 100%;
    height: 100%;
    overflow: scroll;
    position: relative;
  }

  &__close {
    position: absolute;
    top: 1.25em;
    right: 1.25em;
    width: 1em;
    height: 1em;
    cursor: pointer;

    @include media('lg') {
      display: none;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
