.Loading {
  width: 100%;
  position: fixed;
  top: var(--height-header);
  left: 0;
  height: calc(100% - var(--height-header));
  background-color: color('black');
  z-index: map-get($zIndex, 'medium');

  &__inner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-top: 10px;
  }

  &__pane {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: relative;

    &-bordered {
      border: 1px solid color('darkestgray');
    }
  }

  &__disclaimer {
    position: fixed;
    bottom: 3em;
    left: 50%;
    transform: translateX(-50%);
    color: color('darkgray');
    font-style: italic;
    text-align: center;
    width: 100%;
    max-width: 17rem;

    @include media('xs-up') {
      max-width: 19rem;
      bottom: 4em;
      // width: 90vw;
    }

    @include media('md-up') {
      max-width: 90vw;
    }

    & span {
      display: block;
      animation: fade-slide-down 3s ease;
      animation-fill-mode: backwards;
      animation-delay: 3s;
      margin-bottom: 0.5em;
      @include media('sm-up') {
        margin-bottom: 0.3em;
      }
    }
  }

  &__messages {
    position: absolute;
    top: calc(var(--height-header) + 1.2em);
    left: 2.1em;
    line-height: 1.1em;
    z-index: zIndex('medium');
  }

  &__message {
    color: color('white');
    transition: color 0.3s ease-in-out;
    animation: fade-slide-left 0.3s ease-in-out;
    animation-fill-mode: backwards;

    &-completed {
      color: color('darkgray');
    }

    &-later {
      color: color('darkestgray');
    }

    &_link {
      display: block;
      color: color('gray');
      margin-left: 0.5em;
      transition: color 0.1s ease-in-out;

      &:not(&-later):hover {
        color: color('white');
      }

      &-later {
        color: color('darkestgray');
        pointer-events: none;
      }
    }
  }

  $padding: 40px;

  &__explorecycler {
    width: 50%;
    aspect-ratio: 1;
    max-width: 200px;
    position: relative;
    margin: ($padding) 0px;

    & img {
      width: 100%;
      aspect-ratio: 1;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: inset 0px 20px rgba(0, 0, 0, 1);
      border: 1px solid color('darkestgray');
    }

    @include media('sm') {
      max-width: 200px;
    }
  }

  &__exploreprogress {
    position: absolute;
    top: $padding * -1;
    left: $padding * -1;
    width: calc(100% + 2 * $padding);
    height: calc(100% + 2 * $padding);
    transform-origin: 50% 50%;
    transform: rotate(-90deg);

    & .Nav__loaderprogress-wheel {
      transition: stroke-dashoffset 1.5s ease-in-out;
    }
  }

  &__status {
    text-align: center;
    position: absolute;
    bottom: $padding * -1 + 10px;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    line-height: 1.2em;

    &-info {
      margin-top: 0.5em;
      color: color('darkgray');
    }
  }

  &__crawlingmessage {
    text-align: center;

    &-progress {
      color: color('darkgray');
      line-height: 2em;
      animation: fade-in 1s ease;
      animation-delay: 1s;
      animation-fill-mode: backwards;
    }
  }

  &__errors {
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 1, 1, 0.75);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 1000;

    & > div {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      border: 1px solid color('darkgray');
      background-color: color('black');
      min-height: 10rem;
      min-width: 20rem;
      padding: 2rem;
      border-radius: 1rem;

      & p + p {
        margin-top: 1em;
      }

      & > p + p {
        margin-left: 1em;
      }

      & a {
        text-decoration: underline;
      }

      & > div {
        margin-top: 2em;
      }
    }
  }
}
