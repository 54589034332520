.PreviewPlayer {
  position: fixed;
  top: 0;
  left: 50%;
  height: calc(var(--height-header));
  width: 100%;
  max-width: 45%;

  z-index: map-get($zIndex, 'medium');

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transform: translateX(-50%);

  @include media('md-down') {
    top: unset;
    left: unset;
    bottom: 0;
    left: 0;
    width: 100vw;
    max-width: unset;
    height: unset;
    transform: translateX(0%);
  }

  &-inactive {
    color: color('darkestgray');

    @include media('md-down') {
      visibility: hidden;
    }
  }

  &__wrapper {
    height: calc(100% - 2px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.2em;
    background-color: color('black');
    padding: 0 1.2em;
    animation: fade-slide-down 0.3s ease-in-out;

    border-left: 1px solid color('darkestgray');
    border-right: 1px solid color('darkestgray');

    @include media('md-down') {
      padding: 0.7em 0.9em;
      animation: slide-up 0.3s ease-in-out;
      border-top: 1px solid color('darkgray');
      border-left: none;
      border-right: none;
    }
  }

  &__button {
    position: relative;
    width: calc(var(--height-header) * 0.6);
    height: calc(var(--height-header) * 0.6);
    box-sizing: content-box;
  }

  &__progress {
    position: absolute;
    transform: translate(-50%, -50%) rotate(-90deg);
    top: 50%;
    left: 50%;

    &-wheel {
      transition: stroke-dashoffset 0.3s ease-in-out;
      stroke-dasharray: 113.04 113.04;
    }
  }

  &__state {
    position: absolute;
    transform: translate(-50%, -50%);
    height: 40%;
    width: 40%;
    top: 50%;
    left: 50%;

    &-play {
      transform: translate(-40%, -50%);
    }
  }

  & img,
  a {
    height: calc(var(--height-header) * 0.7);
    width: calc(var(--height-header) * 0.7);
    max-width: unset;
  }

  & img {
    object-fit: contain;
    object-position: center;
  }

  & a {
    margin-left: -0.9em;
    svg {
      width: 1.3em;
      height: 1.3em;
    }
  }

  &__albuminfo {
    overflow: hidden;
    white-space: nowrap;

    & p {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.1;
    }

    flex: 1;
    @include media('xs-down') {
      text-align: left;
    }

    @include media('md-down') {
      max-width: unset;
    }
  }
}
