/*

   FONT STYLE
   Docs: http://tachyons.io/docs/typography/font-style/

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.i {
  font-style: italic;
}

.fs-normal {
  font-style: normal;
}

@media (query(md-up)) {
  .i {
    font-style: italic;
  }
  .fs-normal {
    font-style: normal;
  }
}

@media (query(lg-up)) {
  .i {
    font-style: italic;
  }
  .fs-normal {
    font-style: normal;
  }
}

@media (query(xl-up)) {
  .i {
    font-style: italic;
  }
  .fs-normal {
    font-style: normal;
  }
}

@media (query(xxl-up)) {
  .i {
    font-style: italic;
  }
  .fs-normal {
    font-style: normal;
  }
}
