.Legal {
  padding: 1.5rem;
  font-size: 1rem;

  &__header {
    position: unset;
  }

  & > ol {
    @include media('md-up') {
      max-width: 50rem;
    }
  }

  & a {
    text-decoration: underline;
  }

  & em {
    font-weight: bold;
  }

  & h1 {
    font-size: 4rem;
    line-height: 1.2em;
    @include media('md-up') {
      font-size: 6.25rem;
    }
  }

  & h2,
  h3 {
    font-weight: bold;
    font-size: 1.2em;
    display: inline-block;
    margin-left: 1.5em;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }

  & ul,
  ul > li {
    padding: revert;
  }

  & li::marker {
    font-size: 1.1em;
  }

  & h2 {
    text-decoration: underline;
  }

  & ul,
  ol,
  li {
    line-height: 1.2em;
  }

  & ol,
  ul {
    margin: 1em 0;
  }

  & p,
  li {
    margin: 0.5em 0;
  }

  & li {
    display: list-item;
  }

  & > ol > li {
    list-style: decimal;
    list-style-position: inside;
  }

  & > ol > li > ol > li {
    list-style: upper-alpha;
    list-style-position: inside;
    margin-left: 4em;

    & p,
    ul {
      margin-left: -4em;
    }
  }

  & > ol > li > ul > li,
  & > ol > li > ol > li > ul > li {
    list-style: circle;
  }

  &__address,
  &__address li {
    margin: 0 !important;
    padding-left: 0 !important;
    list-style: none !important;
  }
}

#rights-and-choices,
#how-we-collect {
  & li {
    list-style: circle;
    & h3 {
      margin-left: 0;
    }
  }
  & > ul,
  > ol {
    margin-left: 0;
    padding-left: 0;

    & > li {
      list-style: none;
      margin-left: 0;

      & p {
        margin-left: 0;
      }
      & > ul {
        margin-left: 0;
      }
    }
  }
}
