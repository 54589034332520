html,
body {
  background-color: color('black');
  color: color('white');
  width: 100%;
}

.hidden {
  display: none;
  pointer-events: none;
}

.pointer-events-none {
  pointer-events: none;
}

.Button {
  border: 1px solid color('gray');
  border-radius: 2em;
  line-height: 2em;
  height: fit-content;
  white-space: nowrap;
  padding: 0 1em;
}

::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

:root {
  --reactour-accent: #{color('white')};
}

.ClickItem {
  color: color('darkgray');
  transition: color 0.1s ease-in-out, border-color 0.1s ease-in-out;

  &:not(&-active, &-disabled):hover {
    color: color('gray');
  }

  &-active {
    color: color('white');
  }

  &__underline.ClickItem-active {
    color: color('white');
    text-decoration: underline;
    text-underline-offset: 0.3em;
  }

  &-disabled {
    color: color('darkestgray');
    pointer-events: none;
  }
}
