.Umap {
  &__canvas {
    width: 100%;
    height: 100%;
  }

  &__minimap {
    width: 10em;
    aspect-ratio: 2;
    position: fixed;
    margin: 0.6em;
    top: calc(var(--height-header) + var(--height-nav));
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    border: 1px solid color('darkgray');
    border-radius: 1em;
    pointer-events: none;
    opacity: 0;
    animation: fade-in 0.3s ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: backwards;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

    &-mobiletouropen {
      @include media('md-down') {
        transform: translateY(10em);
      }
    }

    @include media('lg') {
      width: 15vw;
      margin: 1.5em;
      top: unset;
      right: unset;
      bottom: 0;
      left: 0;

      &-touropen {
        transform: translateY(-10em);
      }
    }
  }

  &__button-inactive {
    @include media('xxs-down') {
      display: none;
    }
  }

  &__slider {
    position: fixed;
    bottom: 0.6em;
    right: 0.6em;
    background-color: color('black');
    border: 1px solid color('darkgray');
    border-radius: 5.375em;
    padding: 0.375em 0.75em;
    transition: transform 0.3s ease-in-out, bottom 0.3s ease-in-out;
    display: flex;
    flex-direction: row;

    @include media('lg') {
      bottom: 1.5em;
      right: 1.5em;
    }

    @include media('xxs-down') {
      padding: 1em;
      display: grid;
      justify-content: center;
      align-items: center;
    }

    &-shiftup {
      bottom: 5.1em;

      @include media('lg') {
        bottom: 1.5em;
      }
    }

    &_button {
      display: grid;
      justify-content: center;
      align-items: center;

      &-inactive {
        @include media('xxs-down') {
          display: none;
        }
      }

      img {
        animation: fade-in 0.3s ease-in-out;
        fill: color('white');
        opacity: 1;
        width: 1.25em;
        height: 1.25em;
        margin: 0.125em;

        @include media('xxs-down') {
          width: 1.75em;
          height: 1.75em;
        }
      }
    }

    input {
      @include media('xxs-down') {
        display: none;
      }

      &::-webkit-slider-thumb,
      & {
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
      }

      margin: 0 0.5em;
      width: 6em;

      &::-webkit-slider-thumb {
        border: none;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        background: color('white');
        transform: translateY(calc(-0.5em));
      }

      &::-moz-range-thumb {
        border: none;
        width: 1em;
        height: 1em;
        border-radius: 50%;
      }

      &::-webkit-slider-runnable-track {
        background: color('white');
        height: 1px;
      }

      &::-moz-range-track {
        background: color('white');
        height: 1px;
      }
    }
  }
}
