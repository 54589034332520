.LibraryNav {
  position: sticky;
  top: calc(var(--height-header) + var(--height-nav));
  z-index: 1;
  width: 100%;
  height: 5em;
  background-color: color('black');

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5em;

  @include media('sm-down') {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 7em;
    gap: 0.75em;
    border-bottom: 1px solid color('darkestgray');
  }

  &__filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 1.5em;
    width: 100%;
    gap: 1em;
    max-width: 500px;

    .Searchfield {
      width: 100%;
    }

    @include media('sm-down') {
      flex: unset;
      max-width: unset;
    }
  }

  &__sortby {
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    white-space: nowrap;
    gap: 0.5em;
    width: 12em;
    cursor: pointer;

    @include media('sm-down') {
      display: flex;
      height: 100%;
    }
  }

  &__sortbymenubackdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: map-get($zIndex, 'high') + 20;
    background-color: rgba(0, 0, 0, 0.5);
    animation: fade-in 0.3s ease-in-out;
    width: 100vw;
    height: 100vh;
  }

  &__sortbymenu {
    display: none;
    position: absolute;
    top: 0;
    right: -1em;
    width: 10em;
    border: 1px solid color('darkestgray');
    padding: 0.5em;
    background-color: color('black');
    text-align: center;
    z-index: map-get($zIndex, 'high') + 23;
    animation: fade-slide-down 0.3s ease;
    cursor: auto;

    &-open {
      display: block;
    }

    li {
      margin: 0.5em 0;

      &:first-of-type {
        margin-top: 1em;
      }
    }
  }

  &__sortoption {
    text-transform: capitalize;
    padding: 1em;
    display: flex;
    gap: 0.1em;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;

    svg {
      width: 1em;
      height: 1em;
    }
  }

  &__sortdir {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    gap: 0.5em;
  }

  &__selector {
    flex: 1;
    padding: 0 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2em;

    button {
      text-transform: capitalize;
      padding: 0.75em;
    }

    @include media('sm-down') {
      flex: unset;
      width: 100%;
      overflow: scroll hidden;
      justify-content: flex-start;
      align-items: center;
      gap: 3em;
    }
  }
}
