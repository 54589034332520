.MediaList {
  &__wrapper {
    flex-grow: 100;
    width: 100%;
    scrollbar-width: none;

    @include media('md') {
      overflow: scroll;
    }
  }

  // entire table (cannot use overflow-x: hidden with sticky header)
  &__table {
    border-spacing: 0;
    height: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
    @include media('sm-down') {
      width: 100% !important;
    }
  }

  // table header (override top value)
  &__thead {
    position: sticky;
    min-width: 100%;
    height: 3em;
    user-select: none;
    z-index: map-get($zIndex, 'medium') - 2;
    top: 0 !important;
    overflow-x: scroll;
    scrollbar-width: none;

    // hide table header on small displays, but still use it for layout
    @include media('sm-down') {
      display: none;
    }
  }

  // table body
  &__tbody {
    flex: 1;
  }

  // table foot
  &__tfoot {
    padding: 1em;
    opacity: 0.5;
    padding-bottom: 10em;
  }

  // table row
  &__tr {
    height: auto;
    min-width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid color('darkestgray');
    background-color: color('black');

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      padding: 0 0.6em;
      line-height: 1.2em;

      @include media('lg') {
        padding: 0 1.125em;
      }
    }

    // highlighted text when searching
    .highlighted {
      display: inline-block;
      color: color('black');
      background-color: color('white');
      font-weight: 900;
    }
  }

  // table row in thead
  &__headtr {
    @extend .MediaList__tr;
    height: 100%;
    overflow: hidden;
    border-bottom: 1px solid color('darkestgray');
    background-color: color('black');
  }

  // table head cells
  &__th {
    vertical-align: middle;
    clip-path: polygon(0 0, 100% 0, 100% 200%, 0 200%);
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 1);
    height: auto !important;

    span,
    button {
      overflow: visible;
      text-transform: capitalize;
      white-space: nowrap;
      text-decoration: underline;
      text-underline-offset: 0.25em;
      width: min-content;
      pointer-events: all;
      padding: 0.6em;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: color 0.1s ease-in-out;
    }

    button:hover {
      color: color('gray');
    }

    svg {
      margin-left: 0.5em;
    }
  }

  // table body cells
  &__td {
    height: 100%;
    align-items: center;
    position: relative;
    vertical-align: middle;
    min-width: 10px;
    background-color: color('black');

    // expandable cells not in the header open up into tracks
    &-expandable:not(.MediaList__th) {
      cursor: pointer;
    }

    // when expanded, rotate carrot
    &-expanded .MediaList__expandbutton svg {
      transform: rotate(90deg);
    }
  }

  // 1. td: play preview
  &__play_preview {
    overflow: visible;
    text-align: right;

    @include media('sm-down') {
      width: auto !important;
      padding: 0 0.5em;

      // hide on expandable (non-playable) items
      &:not(.MediaList__td-expandable, .MediaList__th) {
        display: none;
      }
    }
  }

  // 1[a] td > div: play button
  &__playbutton {
    float: right;
    height: 2.75em;
    padding: 0.625em;
    transform-origin: 50% 50%;
    transition: transform 0.1s ease-in-out;

    svg {
      height: 1.5em;
      width: 1.5em;
      transition: fill 0.1s ease-in-out;
    }

    &:hover svg {
      fill: color('darkgray');
    }
  }

  // 1.b td > div: expand button
  &__expandbutton {
    height: 2.75em;
    padding: 0.625em;

    svg {
      transition: transform 0.1s ease-in-out;
      transform: rotate(0deg);
      height: 1.5em;
      width: 1.5em;
    }
  }

  // 2. td: the image of the track / album / artist / playlist
  &__images {
    width: 7.875em !important;
    padding: 0.3em 0.5em;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.2em;

    @include media('sm-down') {
      padding-left: 1em;
      width: auto !important;
      gap: 0.3em;
      padding-right: 0em;
    }

    img {
      height: 3.0625em;
      width: 3.0625em;
      object-fit: contain;
      object-position: center;

      @include media('sm-down') {
        float: right;
        width: 2.2em;
        height: 2.2em;
        min-width: 2.2em;
      }
    }

    a {
      height: 3.0625em;
      width: 3.0625em;

      @include media('sm-down') {
        width: 2.2em;
        height: 2.2em;
      }
    }

    // images for non-tracks
    &.MediaList__td-expandable,
    &.MediaList__th {
      @include media('sm-down') {
        width: 4em !important;
        padding-left: 0;
      }

      img {
        height: 6.875em;
        width: 6.875em;
        // we do this to get object-fit to work.
        // there's no need for relative positioning for non-track rows.
        position: absolute;

        @include media('sm-down') {
          float: left;
          height: 4em;
          width: 4em;
          min-width: 4em;
        }
      }

      a {
        height: 6.875em;
        width: 6.875em;

        @include media('sm-down') {
          height: 4em;
          width: 4em;
        }
      }

      svg {
        display: none;
      }
    }
  }

  &__platformicon {
    display: grid;
    justify-content: center;
    align-items: center;

    svg {
      transition: fill 0.1s ease-in-out;
      width: 1.1em;
      height: 1.1em;
    }

    &-spotify:hover svg {
      fill: #1db954;
    }

    &-apple:hover svg {
      fill: #fc3c44;
    }
  }

  &__spotifyicon {
    transition: fill 0.1s ease-in-out;
    width: 1.1em;
    height: 1.1em;

    &:hover {
      fill: #1db954;
    }
  }

  &__appleicon {
    @extend .MediaList__spotifyicon;

    &:hover {
      fill: #fc3c44;
    }
  }

  // 3. td: small preview (spans 6 columns on mobile), only for non-expandable
  //        rows in the table body.
  &__smalldisplay {
    display: none;
    font-size: 0.8em;
    vertical-align: middle;
    cursor: pointer;

    @include media('sm-down') {
      &:not(.MediaList__th) {
        display: flex;
        flex: 1;
      }
    }

    div {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      padding: 1.1em 0;
      padding-top: calc(1.1em + 3px);
      gap: 0.2em;
      width: 100%;

      span {
        color: color('gray');
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1;

        &:first-of-type {
          color: color('white');
        }
      }
    }

    &.MediaList__td-expandable {
      padding-left: 1em;
    }
  }

  // 4,5,6,7,8. td: name, artist, album, relase date, cluster rank
  &__name,
  &__artist,
  &__album,
  &__release_date,
  &__cluster_rank {
    @include media('sm-down') {
      display: none;
    }
  }

  // 8. save/select button
  &__save,
  &__select {
    width: auto !important;
    padding: 0 1em;

    @include media('md-down') {
      width: 4rem !important;
      padding-right: 1em;
      text-align: right;

      button {
        float: right;
      }
    }
  }

  // 8.a select button element
  &__selectbutton {
    border-color: transparent;
    color: color('white');
    transition: color 0.1s ease-in-out;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.4em 1em;
    opacity: 0.2;
    transition: opacity 0.2s ease-in-out;
    height: 3em;

    svg {
      height: 1.2em;
      width: 1.2em;
    }

    path {
      transition: 0.1s ease-in-out;
      fill: color('white');
    }

    &:hover path {
      fill: color('darkgray');
    }

    &-active {
      opacity: 1;
    }
  }

  // 8.b add button element
  &__addbutton {
    font-size: 1em;
    border-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.4em 1em;

    svg {
      height: 1.2em;
      width: 1.2em;
      fill: color('gray');
    }
  }

  // resizer element
  &__resizer {
    display: inline-block;
    width: 1rem;
    height: 2em;
    position: absolute;
    right: 3px;
    bottom: -0.5em;
    transform: translate(50%, 0%);
    z-index: 1;
    touch-action: none;
    cursor: col-resize;

    &::after {
      content: '';
      height: 100%;
      position: absolute;
      width: 1rem;
      border-right: 1px solid color('darkestgray');
      left: calc(50% - 1rem);
    }
  }
}
