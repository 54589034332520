/*
   WIDTHS
   Docs: http://tachyons.io/docs/layout/widths/
   Base:
     w = width
   Modifiers
     1 = 1st step in width scale
     2 = 2nd step in width scale
     3 = 3rd step in width scale
     4 = 4th step in width scale
     5 = 5th step in width scale
     -10  = literal value 10%
     -20  = literal value 20%
     -25  = literal value 25%
     -30  = literal value 30%
     -33  = literal value 33%
     -34  = literal value 34%
     -40  = literal value 40%
     -50  = literal value 50%
     -60  = literal value 60%
     -70  = literal value 70%
     -75  = literal value 75%
     -80  = literal value 80%
     -90  = literal value 90%
     -100 = literal value 100%
     -third      = 100% / 3 (Not supported in opera mini or IE8)
     -two-thirds = 100% / 1.5 (Not supported in opera mini or IE8)
     -auto       = string value auto
    Media Query Extensions:
      -md  = medium (768 up)
      -lg  = large (1024 up)
      -xl  = extra large (1440 up)
      -xxl = extra extra large (1920 up)
*/

/* Width Scale */

.w1 {
  width: 1rem;
}

.w2 {
  width: 2rem;
}

.w3 {
  width: 4rem;
}

.w4 {
  width: 8rem;
}

.w5 {
  width: 16rem;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}

.w-34 {
  width: 34%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.w-third {
  width: calc(100% / 3);
}

.w-two-thirds {
  width: calc(100% / 1.5);
}

.w-auto {
  width: auto;
}

@media (query(md-up)) {
  .w1-md {
    width: 1rem;
  }
  .w2-md {
    width: 2rem;
  }
  .w3-md {
    width: 4rem;
  }
  .w4-md {
    width: 8rem;
  }
  .w5-md {
    width: 16rem;
  }
  .w-10-md {
    width: 10%;
  }
  .w-20-md {
    width: 20%;
  }
  .w-25-md {
    width: 25%;
  }
  .w-30-md {
    width: 30%;
  }
  .w-33-md {
    width: 33%;
  }
  .w-34-md {
    width: 34%;
  }
  .w-40-md {
    width: 40%;
  }
  .w-50-md {
    width: 50%;
  }
  .w-60-md {
    width: 60%;
  }
  .w-70-md {
    width: 70%;
  }
  .w-75-md {
    width: 75%;
  }
  .w-80-md {
    width: 80%;
  }
  .w-90-md {
    width: 90%;
  }
  .w-100-md {
    width: 100%;
  }
  .w-third-md {
    width: calc(100% / 3);
  }
  .w-two-thirds-md {
    width: calc(100% / 1.5);
  }
  .w-auto-md {
    width: auto;
  }
}

@media (query(lg-up)) {
  .w1-lg {
    width: 1rem;
  }
  .w2-lg {
    width: 2rem;
  }
  .w3-lg {
    width: 4rem;
  }
  .w4-lg {
    width: 8rem;
  }
  .w5-lg {
    width: 16rem;
  }
  .w-10-lg {
    width: 10%;
  }
  .w-20-lg {
    width: 20%;
  }
  .w-25-lg {
    width: 25%;
  }
  .w-30-lg {
    width: 30%;
  }
  .w-33-lg {
    width: 33%;
  }
  .w-34-lg {
    width: 34%;
  }
  .w-40-lg {
    width: 40%;
  }
  .w-50-lg {
    width: 50%;
  }
  .w-60-lg {
    width: 60%;
  }
  .w-70-lg {
    width: 70%;
  }
  .w-75-lg {
    width: 75%;
  }
  .w-80-lg {
    width: 80%;
  }
  .w-90-lg {
    width: 90%;
  }
  .w-100-lg {
    width: 100%;
  }
  .w-third-lg {
    width: calc(100% / 3);
  }
  .w-two-thirds-lg {
    width: calc(100% / 1.5);
  }
  .w-auto-lg {
    width: auto;
  }
}

@media (query(xl-up)) {
  .w1-xl {
    width: 1rem;
  }
  .w2-xl {
    width: 2rem;
  }
  .w3-xl {
    width: 4rem;
  }
  .w4-xl {
    width: 8rem;
  }
  .w5-xl {
    width: 16rem;
  }
  .w-10-xl {
    width: 10%;
  }
  .w-20-xl {
    width: 20%;
  }
  .w-25-xl {
    width: 25%;
  }
  .w-30-xl {
    width: 30%;
  }
  .w-33-xl {
    width: 33%;
  }
  .w-34-xl {
    width: 34%;
  }
  .w-40-xl {
    width: 40%;
  }
  .w-50-xl {
    width: 50%;
  }
  .w-60-xl {
    width: 60%;
  }
  .w-70-xl {
    width: 70%;
  }
  .w-75-xl {
    width: 75%;
  }
  .w-80-xl {
    width: 80%;
  }
  .w-90-xl {
    width: 90%;
  }
  .w-100-xl {
    width: 100%;
  }
  .w-third-xl {
    width: calc(100% / 3);
  }
  .w-two-thirds-xl {
    width: calc(100% / 1.5);
  }
  .w-auto-xl {
    width: auto;
  }
}

@media (query(xxl-up)) {
  .w1-xxl {
    width: 1rem;
  }
  .w2-xxl {
    width: 2rem;
  }
  .w3-xxl {
    width: 4rem;
  }
  .w4-xxl {
    width: 8rem;
  }
  .w5-xxl {
    width: 16rem;
  }
  .w-10-xxl {
    width: 10%;
  }
  .w-20-xxl {
    width: 20%;
  }
  .w-25-xxl {
    width: 25%;
  }
  .w-30-xxl {
    width: 30%;
  }
  .w-33-xxl {
    width: 33%;
  }
  .w-34-xxl {
    width: 34%;
  }
  .w-40-xxl {
    width: 40%;
  }
  .w-50-xxl {
    width: 50%;
  }
  .w-60-xxl {
    width: 60%;
  }
  .w-70-xxl {
    width: 70%;
  }
  .w-75-xxl {
    width: 75%;
  }
  .w-80-xxl {
    width: 80%;
  }
  .w-90-xxl {
    width: 90%;
  }
  .w-100-xxl {
    width: 100%;
  }
  .w-third-xxl {
    width: calc(100% / 3);
  }
  .w-two-thirds-xxl {
    width: calc(100% / 1.5);
  }
  .w-auto-xxl {
    width: auto;
  }
}
