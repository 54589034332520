.Login {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 2em;
  gap: 2em;
  min-height: 100vh;

  &__inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2em;

    @include media('sm') {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__logo-icon {
    width: 100%;
    height: auto;
    opacity: 0.6;
  }

  &__description {
    white-space: normal;
    align-self: flex-start;
    @include media('md-up') {
      white-space: pre-line;
    }

    @include media('lg-up') {
      align-self: center;
    }
  }

  &__login-methods {
    display: flex;
    flex-direction: column;
    gap: 1em;
    @include media('lg') {
      flex-direction: row;
    }
  }

  &__login-button {
    text-align: left;
    width: 17em;
    position: relative;
    opacity: 0.8;
    transition: opacity 0.1s ease-in-out, background-color 0.2s ease-in-out,
      color 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }

    & span {
      display: block;
      position: absolute;
      right: 0.75em;
      top: 0;
      transition: transform 0.2s ease-in-out;
    }

    &--active {
      background-color: color('white');
      color: color('black');
      opacity: 1;

      & span {
        transform: rotate(90deg);
      }
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__login-flow {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 0.5em;
    transition: opacity 0.3s ease-in-out;

    .Login__statemessage {
      @include media(lg-down) {
        position: unset;
        transform: unset;
      }
    }
  }

  &__login-flow--hidden {
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    opacity: 0.2;

    &:last-of-type {
      opacity: 0;
    }
  }

  &__statemessage {
    position: absolute;
    bottom: -0.5em;
    transform: translateY(100%);

    & span {
      display: block;
      animation-delay: 0.5s;
      animation: fade-slide-right 0.4s ease-in-out;
      padding-left: 1em;
      margin-bottom: 0.3em;
      width: 100%;
    }
  }

  &__beta-label {
    font-size: 0.75rem;
    position: absolute;
    top: 1.25em;
    right: 1em;
    @include media('lg') {
      top: 0;
      right: 2em;
      font-size: inherit;
      position: unset;
      margin-left: auto;
      margin-right: 2.2vw;
    }
  }

  &__applepopup {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32rem;
    max-width: 90vw;
    height: 9rem;
    background-color: color('black');
    border: color('lightgray') 1px solid;
    border-radius: 0.625rem;
    padding: 1rem 1.1875rem;
    z-index: 1000;

    &,
    button {
      color: color('lightgray');
      font-size: 1.125rem;
      line-height: 1.3em;
    }
  }

  &__applepopupbuttons {
    display: flex;

    div:first-child {
      margin-right: auto;
      align-self: center;
    }

    button:last-child {
      border: color('lightgray') 1px solid;
      border-radius: 3.75rem;
      padding: 0.25rem 1rem;
      margin-left: 1rem;
    }
  }

  &__backgroundcover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: color('black');
    z-index: 999;
    opacity: 0.6;
  }

  &__footer {
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__legal {
    & li {
      display: inline;
    }
  }
}
