.Searchfield {
  position: relative;
  // height: calc(2em + 2px);

  button {
    position: absolute;
    right: 0;
    top: 50%;
    padding-right: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(-50%);

    & svg {
      height: 1.2em;
      width: 1.2em;
    }
  }

  input {
    border: 1px solid color('darkestgray');
    border-radius: 2em;
    line-height: 2em;
    height: fit-content;
    padding: 0 1em;
    padding-right: 2em;
    box-sizing: border-box;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center right 1em;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &__empty button {
    pointer-events: none;
  }
}
